import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes"
import checkPermissions from "@/utils/checkPermissions"
import { useUserStore } from "@/stores/user"
import { getAccountInfo, getAccountPermission } from "@/api/account/api"
import { ElLoading } from "element-plus"

/**
 * @typedef {import('vue-router').RouteLocationRaw} RouteLocationRaw
 * @typedef {import('vue-router').Router} Router
 */
const router = createRouter({
    history: createWebHistory(),
    routes,
})

let loadingInstance

/**
 * 在每次導航之前進行權限和驗證檢查。
 * @param {RouteLocationRaw} to - 正在導航至的目標路由。
 * @returns {boolean | Route} - 如果繼續導航，返回 `true`，如果要重定向到其他路由，返回 `Route` 物件。
 */
router.beforeEach(async (to) => {
    const userStore = useUserStore()
    const isLogin = !!userStore.formatUserToken
    const hasUserData = checkHasUserData(userStore)

    loadingInstance = ElLoading.service({ fullscreen: true, text: "Loading" })

    // 如果路由需要驗證，但用戶未登錄，則導向登錄頁面
    if (to.meta.requiresAuth && !isLogin) {
        return {
            name: "login",
            query: { redirect: to.path },
        }
    }

    // 如果路由需要驗證，且用戶已登錄，但用戶資料未取得，則取得用戶資料
    if (to.meta.requiresAuth && isLogin && !hasUserData) {
        try {
            await getUserData(userStore)
        } catch (error) {
            userStore.setUserToken("")

            return {
                name: "login",
                query: { redirect: to.path },
            }
        }
    }

    const hasPermission = checkPermissions(to?.meta?.permissions, userStore)
    // 如果路由需要權限，但用戶沒有權限，則導向禁止頁面
    if (to.meta.permissions && !hasPermission) {
        return {
            name: "forbidden",
            query: { redirect: to.path },
        }
    }

    return
})

router.afterEach(() => {
    loadingInstance.close()
})

/**
 * 取得用戶 info、permission
 * @param {object} userStore userStore
 * @returns {void}
 */
async function getUserData(userStore) {
    const result = await Promise.all([getAccountInfo(), getAccountPermission()])
    userStore.setUserInfo(result[0].data)
    userStore.setUserPermissions(result[1].data)
}

/**
 * 確保當前用戶具備用戶訊息
 * @param {object} userStore userStore
 * @returns {boolean} 有則 true、沒有則 false
 */
function checkHasUserData(userStore) {
    if (!userStore.userInfo) return false
    if (!userStore.userPermissions) return false

    return true
}

export default router
