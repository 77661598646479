import { createI18n } from "vue-i18n"
import zhTW from "@/locales/zh-TW"

const i18n = createI18n({
    locale: "zh-TW",
    fallbackLocale: "zh-TW",
    messages: {
        "zh-TW": zhTW,
    },
})

export default i18n
