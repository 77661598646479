/**
 * 用途及目標：
 * 1. 封裝額外一隻 axios 實體，針對錯誤訊息進行處理，另外會繼續拋出錯誤
 * 2. 封裝 api baseURL，會針對開發、測試環境去進行變動
 */

import axios from "axios"
import customErrorCode from "./customErrorCode"
// import * as refreshApi from "@/api/refresh/api"
const baseURL = import.meta.env.DEV ? "/api/v1" : import.meta.env.VITE_BASE_URL
const timeout = 20000

/* 不具備錯誤處理的 axios instance（只具備對於訊息的處理） */
export const instance = axios.create({
    baseURL,
    timeout,
})

instance.interceptors.request.use(
    function (config) {
        const token = JSON.parse(localStorage.getItem("userToken"))?.access
        config.headers["Authorization"] =
            config.headers["Authorization"] ?? `Bearer ${token}`
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    function (response) {
        if (!response.config.metadata) {
            Object.assign(response.config, {
                metadata: {
                    endTime: new Date(),
                },
            })
        }

        return response
    },

    async function (error) {
        const response = error?.response

        // 當錯誤為 401 時，會嘗試刷新重新登入
        // if (response?.status == 401) {

        // const refreshError = await refreshToken()
        // 當重新登入一次失敗，會顯示錯誤｜當重新登入一次成功，會重新開始請求
        // if (!refreshError) return restartRequest(error)
        // }

        error.message = getCustomErrorMessage(
            response?.status,
            response?.data,
            error.code
        )

        error.status = error?.response?.status || "--"
        error.code = error?.response?.data?.code || "--"

        return Promise.reject(error)
    }
)

/**
 * 根據給定的狀態碼和訊息獲取自訂錯誤訊息。（也會處理超時錯誤）
 * @param {number} status - HTTP 狀態碼。
 * @param {string} responseData - response data
 * @param {string} errorCode - response error code
 * @returns {string} 自訂錯誤訊息。
 */
export function getCustomErrorMessage(status, responseData, errorCode) {
    /**
     * 所有錯誤碼對應中文
     */
    const STATUS_MESSAGES = {
        400: "發生意外錯誤，請重新嘗試",
        401: "用戶沒有登入系統，請登入",
        403: "用戶沒有當前功能權限",
        404: "請求的資源不存在",
        405: "HTTP 請求方法錯誤，請回報工程團隊修復",
        410: "請求的資源已過期",
        500: "伺服器異常錯誤",
        502: "伺服器服務中出現異常",
        503: "伺服器暫時無法處理請求",
        504: "伺服器沒有回應，請等候後重新嘗試",
        default: "發生意外錯誤，請重新嘗試",
    }

    if (errorCode === "ECONNABORTED") return "網路請求超時，請重新嘗試"
    if (status == 400)
        return customErrorCode[responseData.code] || STATUS_MESSAGES[status]

    return STATUS_MESSAGES[status] || STATUS_MESSAGES.default
}

/**
 * 處理 401 fresh token，刷新 token
 * 成功後：更新 token、refresh token
 * 當失敗後：登出、清除 token
 * @returns {object} refreshTokenError
 */
// async function refreshToken() {
//     const userStore = useUserStore()
//     const { refresh: refreshToken } = JSON.parse(
//         localStorage.getItem("userToken")
//     )

//     try {
//         // 以當前的 access token 刷新
//         const response = await refreshApi.refreshToken({
//             data: { access: refreshToken },
//         })
//         // 刷新成功則更新 token、user store 的 token
//         userStore.setUserToken(response.data)
//     } catch (error) {
//         // 刷新失敗則登出，並且清除 token、pinia
//         userStore.logout()
//         return true
//     }
// }

/**
 * 重新開始請求
 * @param {axiosError} error axios response error
 * @returns {axiosRequest} axios instance request
 */
// async function restartRequest(error) {
//     const newToken = JSON.parse(localStorage.getItem("userToken"))?.access
//     error.config.headers["Authorization"] = `Bearer ${newToken}`
//     return instance(error.config)
// }
