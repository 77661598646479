/**
 * @typedef {object} RouteMeta 用於 Vue 路由的身份驗證元資料（meta）
 * @property {boolean} requiresAuth 表示該路由需要身份驗證，值為 `true`。
 * @property {[]} permissions 所需的權限清單。
 * @property {boolean} isSubShow 是否為子項目展示。
 * @property {string} subIndex 子項目索引。
 * @property {string} subGroup 子項目群組。
 * @property {string} subGroupName 子項目群組名稱。
 * @property {string} icon 可選 top navbar 圖標
 * @property {boolean} hasSubMenuGroup 是否在 menu 中以群組顯示
 * @property {string} subMenuGroup menu 群組名稱
 * @property {string} subMenuGroupIndex menu 群組 index
 */

/**
 * 創建用於 Vue 路由的身份驗證元資料（meta）。
 * @param {object} options - 選項物件，包含以下屬性：
 * @param {boolean} options.requiresAuth 表示該路由需要身份驗證，值為 `true`。
 * @param {string[]} options.permissions - 所需的權限清單。
 * @param {boolean} options.isSubShow - 是否為子項目展示。
 * @param {string} options.subIndex - 子項目索引。
 * @param {string} options.subGroup - 子項目群組。
 * @param {string} options.subGroupName - 子項目群組名稱。
 * @param {string} options.icon 可選 top navbar 圖標
 * @param {boolean} options.hasSubMenuGroup - 是否在 menu 中以群組顯示
 * @param {string} options.subMenuGroup - menu 群組名稱
 * @param {string} options.subMenuGroupIndex - menu 群組 index
 * @throws {Error} 如果缺乏所需的參數，將拋出錯誤。
 * @returns {RouteMeta} 創建的身份驗證元資料（meta）物件。
 */
export default function createAuthRouteMeta({
    requiresAuth = true,
    permissions,
    isSubShow = false,
    subIndex = "",
    subGroup,
    subGroupName = "",
    hasSubMenuGroup = false,
    subMenuGroup = "",
    subMenuGroupIndex = "",
    icon = "",
}) {
    if (isSubShow) {
        if (Object.values(arguments[0]).some((param) => param === undefined))
            throw Error("缺乏所需的參數")
        return {
            requiresAuth,
            permissions,
            isSubShow,
            subIndex,
            subGroup,
            subGroupName,
            hasSubMenuGroup,
            subMenuGroup,
            subMenuGroupIndex,
            icon,
        }
    } else {
        if ([permissions, subGroup].some((param) => param === undefined))
            throw Error("缺乏所需的參數")
        return {
            requiresAuth,
            permissions,
            isSubShow,
            subIndex,
            subGroup,
            subGroupName,
            hasSubMenuGroup,
            subMenuGroup,
            subMenuGroupIndex,
            icon,
        }
    }
}
