import checkPermissionConfig from "@/utils/checkPermissionConfig/index"
import { useUserStore } from "@/stores/user"

// ! 註解 formatFunction 要更加清楚
/**
 * 保護路由的實用函式，根據使用者權限進行判斷。
 * @param {object} options - 選項設定。
 * @param {object} options.to - 目標路由的路由物件。
 * @param {string} options.goBackPath [{ name: "forbidden" }] - 在權限不足時的備用路徑。
 * @param {object} options.PERMISSIONS - 所需權限的設定。
 * @param {Function} options.api - 用於獲取資料的 API 函式。
 * @param {Function} options.formatFunction 用戶 format Api response 讓其符合 router protect 驗證的格式
 * @returns {Promise<[boolean, ApiResponse]|string>} 若使用者具有權限則返回 [true, response]，否則返回 [goBackPath]。
 */
export default async function useRouterProtect({
    to,
    goBackPath = { name: "forbidden-index" },
    PERMISSIONS,
    api,
    formatFunction,
}) {
    try {
        const response = await api(to.params.id)
        const formatResponse = formatFunction
            ? formatFunction(response)
            : response
        const userStore = useUserStore()

        const hasPermissions = checkPermissionConfig(PERMISSIONS, userStore, {
            department: formatResponse.data.creator?.department?.uuid,
            status: formatResponse.data.status,
            creatorUuid: formatResponse.data.creator?.uuid,
            roleLevel: formatResponse.data.creator?.role?.level,
        })

        if (!hasPermissions) throw Error()
        return [true, response]
    } catch (error) {
        return [goBackPath]
    }
}
