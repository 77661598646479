<script setup>
    import { computed } from "vue"
    import checkPermissionConfig from "@/utils/checkPermissionConfig/index"
    import { useUserStore } from "@/stores/user"

    const props = defineProps({
        requirePermission: {
            type: [String, Array],
            required: true,
        },
        assetData: {
            type: Object,
            required: false,
            default: null,
        },
        config: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    })

    const userStore = useUserStore()
    const { hasPermission } = useHasPermission(userStore, props)

    /**
     * @typedef {object} AssetData 資產物件
     * @property {string} department 創建者部門
     * @property {string} status 物件狀態
     * @property {string} creatorUuid 創建者 uuid
     * @property {number} roleLevel 物件 level（角色）
     */

    /**
     * @typedef {object} HasPermission 資產物件
     * @property {import("vue").Ref<boolean>} hasPermission 創建者部門
     */

    /**
     * 確認使用者是否擁有特定權限的計算屬性。
     * @param {object} userStore - userStore
     * @param {object} props - 組件的屬性。
     * @param {AssetData} props.assetData - 物件狀態
     * @returns {HasPermission} - 布林值的計算屬性，指示是否擁有所需權限。
     */
    function useHasPermission(userStore, props) {
        const hasPermission = computed(() => {
            return checkPermissionConfig(
                props.requirePermission,
                userStore,
                props.assetData,
                props.config
            )
        })
        return { hasPermission }
    }
</script>

<template>
    <div :style="{ display: hasPermission ? 'inline-block' : 'none' }">
        <slot :user-info="userStore.userInfo"></slot>
    </div>
</template>
