/**
 * @typedef { import("vue").reactive } Reactive
 */

/**
 * 檢查使用者權限是否擁有指定的權限之一（較為簡易的驗證）
 * @param {string[]} permissions 指定的權限清單。
 * @param {Reactive} userStore 使用者存儲的 Vue Reactive 物件。
 * @returns {boolean} 若使用者擁有指定的權限之一，則返回 `true`，否則返回 `false`。
 */
export default function checkPermissions(permissions, userStore) {
    const requiredPermissions = permissions ?? []
    const userPermissions = userStore.formatUserPermissions

    if (
        userPermissions &&
        requiredPermissions.some((permission) =>
            userPermissions.includes(permission)
        )
    ) {
        return true
    }
    return false
}

/**
 * 檢查使用者權限是否滿足限制條件。
 * @param {{id:string,isActive:boolean}[]} userPermissions - 使用者的權限清單，每個權限物件包含 permissionId 和 isActive 屬性。
 * @param {string[]} validatePermissions - 需要驗證的權限清單，包含要求的 permissionId。
 * @returns {boolean} 若使用者權限滿足驗證條件，返回 true；否則返回 false。
 */
export function checkPermissionsOfLimitCondition(
    userPermissions,
    validatePermissions
) {
    // 若沒有需要驗證的權限，直接返回 true
    if (validatePermissions.length === 0) {
        return true
    }

    // 遍歷需要驗證的權限清單
    for (const permissionId of validatePermissions) {
        // 在使用者權限清單中查找對應的權限
        const userPermission = userPermissions.findLast((permission) => {
            return permission.id === permissionId
        })

        // 若找到了對應的權限且 isActive 為 true，則繼續驗證下一個權限
        if (userPermission && userPermission?.isActive) {
            continue
        }

        // 若找不到對應的權限或者 isActive 不為 true，則返回 false
        return false
    }

    // 都通過了驗證，則返回 true
    return true
}
