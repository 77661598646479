<script setup>
    defineProps({
        items: {
            type: Array,
            required: true,
        },
        currentItem: {
            type: Object,
            required: true,
        },
        isCollapse: {
            type: Boolean,
            required: true,
        },
    })

    const emits = defineEmits(["update:is-collapse"])
</script>

<template>
    <el-menu
        :default-active="currentItem.meta?.subIndex || ''"
        style="height: calc(100vh - 60px)"
        :collapse="isCollapse"
        @open="() => {}"
        @close="() => {}"
    >
        <el-menu-item class="base-arrow-wrapper" @click="() => {}">
            <el-tooltip effect="dark" content="收合選單" placement="right">
                <base-svg-icon
                    :class="[
                        isCollapse ? 'transition' : '',
                        'base-arrow',
                        'outline-none',
                    ]"
                    name="sidebar-open"
                    class="!-ml-3"
                    @click="emits('update:is-collapse', !isCollapse)"
                />
            </el-tooltip>
        </el-menu-item>

        <div v-if="!isCollapse">
            <BaseNavbarMenuItems :items="items"></BaseNavbarMenuItems>
        </div>
    </el-menu>
</template>

<style lang="scss" scoped>
    .base-arrow-wrapper {
        justify-content: flex-start;
        cursor: auto;
        &:hover {
            background-color: $fill-blank;
        }
    }

    .base-arrow {
        cursor: pointer;
    }

    .transition {
        transform: rotate(180deg);
    }
</style>
