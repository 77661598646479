import { defineStore, getActivePinia } from "pinia"

/**
 * 重置所有 pinia
 */
export default function resetAllStores() {
    const activepinia = getActivePinia()
    if (activepinia) {
        Object.entries(activepinia.state.value)?.forEach(
            ([storeName, state]) => {
                const storeDefinition = defineStore(storeName, state)
                const store = storeDefinition(activepinia)
                store.$reset()
            }
        )
    }
}
