import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import authRoutes from "./modules/auth"
import commonRoutes from "./modules/common"
import dataManage from "./modules/dataManage"
import audiencesRoutes from "./modules/audiences"
import accountManageRoutes from "./modules/accountManage"
import marketing from "./modules/marketing"
import marketingResources from "./modules/marketingResources"

const routes = [
    {
        path: "/",
        redirect: "/audiences",
        name: "home",
    },
    ...authRoutes,
    ...commonRoutes,
    ...dataManage,
    ...audiencesRoutes,
    ...marketing,
    ...marketingResources,
    ...accountManageRoutes,
    {
        path: "/about",
        name: "About",
        component: () => import("@/pages/About.vue"),
    },
    {
        path: "/forbidden",
        name: "forbidden",
        component: DefaultLayout,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                component: () => import("@/pages/ForbiddenPage.vue"),
                name: "forbidden-index",
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        component: DefaultLayout,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                component: () => import("@/pages/NotFoundPage.vue"),
                name: "not-found",
            },
        ],
    },
]

export default routes
