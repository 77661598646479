export default [
    {
        path: "/login",
        name: "login",
        meta: {
            requiresGuest: true,
        },
        component: () => {
            switch (import.meta.env.VITE_LOGIN_MODE) {
                case "normal":
                    return import("@/pages/AuthGroup/LoginPage.vue")
                case "ad":
                    return import("@/pages/AuthGroup/ADLoginPage.vue")
                default:
                    return import("@/pages/AuthGroup/LoginPage.vue")
            }
        },
    },
    {
        path: "/forget-password",
        name: "forget-password",
        meta: {
            requiresGuest: true,
        },
        component: () => import("@/pages/AuthGroup/ForgetPasswordPage.vue"),
    },
    {
        path: "/set-password",
        name: "set-password",
        meta: {
            requiresGuest: true,
        },
        component: () => import("@/pages/AuthGroup/SetPasswordPage.vue"),
        beforeEnter: (to) => {
            if (!to.query?.token || !to.query?.type) return { name: "login" }
        },
    },
]
