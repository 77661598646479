/**
 * format User list
 * @param {object} response user response
 * @returns {{
 *   uuid: string,
 *   name: string,
 *   status: string,
 *   department: string,
 *   role: string,
 *   roleName: string,
 *   email: string,
 *   level: string,
 *   departmentUuid: string
 * }[]} FormatUser
 */
export function formatUserList(response) {
    return response.results
}

/**
 * format user data response 讓用戶資訊符合 routerProtect 需要的格式。
 * @param {object} userDataResponse userData 的 response
 * @returns {{data: {
 *   creator: {
 *     department: {
 *       name: string,
 *       uuid: string
 *     },
 *     name: string,
 *     role: {
 *       name: string,
 *       slug: string,
 *       level: number
 *     },
 *     uuid: string
 *   },
 *  status: string
 * }}} routerProtect 需要的格式
 */
export function formatUserDataToRouterProtectStyle(userDataResponse) {
    const result = {
        data: {
            creator: {
                department: {
                    name: userDataResponse.data.department,
                    uuid: userDataResponse.data.departmentUuid,
                },
                name: "",
                role: {
                    name: "",
                    slug: "",
                    level: "",
                },
                uuid: "",
            },
            status: userDataResponse.data.status,
        },
    }
    return result
}
