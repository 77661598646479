import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import PERMISSIONS from "@/utils/permissions/index.js"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import * as assetApi from "@/api/asset/api"
import useRouterProtect from "@/composables/useRouterProtect/index.js"

export default [
    {
        path: "/marketing-resources",
        component: DefaultLayout,
        redirect: () => {
            return { name: "edm-list" }
        },
        name: "marketing-resources",
        meta: {
            isShow: true,
            name: "行銷資源管理",
            index: "5",
            position: "left",
            menuGroup: "marketing-resources",
        },
        children: [
            {
                name: "marketing-resources-assets",
                path: "",
                redirect: () => {
                    return { name: "edm-list" }
                },
                meta: {
                    isShow: true,
                    requiresAuth: true,
                    permissions: [PERMISSIONS.GET_SMS],
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "assets",
                    hasSubMenuGroup: true,
                    subMenuGroup: "素材管理",
                    subMenuGroupIndex: "1",
                },
                children: [
                    // edm 素材管理
                    {
                        path: "edm", // 素材管理列表 edm
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesEDMPage.vue"
                            ),
                        name: "edm-list",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.GET_EDM],
                            isSubShow: true,
                            subIndex: "1",
                            subGroup: "edm",
                            subGroupName: "EDM素材",
                            hasSubMenuGroup: false,
                            subMenuGroup: "素材管理",
                            subMenuGroupIndex: "1",
                        },
                    },
                    {
                        path: "edm/preference", // 素材管理列表 edm
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesEDMPreferencePage.vue"
                            ),
                        name: "edm-preference",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.EDIT_EDM],
                            isSubShow: false,
                            subGroup: "edm",
                        },
                    },
                    {
                        path: "edm/add", // 新增素材 edm
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesEDMAddPage.vue"
                            ),
                        name: "add-edm",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.ADD_EDM],
                            isSubShow: false,
                            subGroup: "edm",
                        }),
                    },
                    {
                        path: "edm/:id", // 查看詳細素材 edm
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesEDMDetailPage.vue"
                            ),
                        name: "edm-detail",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.GET_EDM],
                            isSubShow: false,
                            subGroup: "edm",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.GET_EDM,
                                api: assetApi.getEdm,
                            })

                            if (path === true) {
                                to.params.initialEdmResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "edm/:id/edit", // 編輯素材 edm
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesEDMEditPage.vue"
                            ),
                        name: "edit-edm",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.EDIT_EDM],
                            isSubShow: false,
                            subGroup: "edm",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.EDIT_EDM,
                                api: assetApi.getEdm,
                            })

                            if (path === true) {
                                to.params.initialEdmResponse = response
                            }
                            return path
                        },
                    },

                    {
                        path: "edm/:id/review", // 素材審核 edm
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesEDMReviewPage.vue"
                            ),
                        name: "review-edm",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.REVIEW_EDM],
                            isSubShow: false,
                            subGroup: "edm",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.REVIEW_EDM,
                                api: assetApi.getEdm,
                            })

                            if (path === true) {
                                to.params.initialEdmResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "sms", // 素材管理列表 sms
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesSMSPage.vue"
                            ),
                        name: "sms-list",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.GET_SMS],
                            isSubShow: true,
                            subIndex: "2",
                            subGroup: "sms",
                            subGroupName: "簡訊素材",
                            hasSubMenuGroup: false,
                            subMenuGroup: "素材管理",
                            subMenuGroupIndex: "2",
                        },
                    },
                    {
                        path: "sms/:id", // 素材管理 sms 細節
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesSMSDetailPage.vue"
                            ),
                        name: "sms-detail",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.GET_SMS],
                            isSubShow: false,
                            subGroup: "sms",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.GET_SMS,
                                api: assetApi.getSms,
                            })

                            if (path === true) {
                                to.params.initialSmsResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "sms/add", // 新增素材 sms
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesSMSAddPage.vue"
                            ),
                        name: "add-sms",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.ADD_SMS],
                            isSubShow: false,
                            subGroup: "sms",
                        }),
                    },
                    {
                        path: "sms/:id/edit", // 編輯素材 sms
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesSMSEditPage.vue"
                            ),
                        name: "edit-sms",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.EDIT_SMS],
                            isSubShow: false,
                            subGroup: "sms",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.EDIT_SMS,
                                api: assetApi.getSms,
                            })

                            if (path === true) {
                                to.params.initialSmsResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "sms/:id/review", // 審核素材 sms
                        component: () =>
                            import(
                                "@/pages/MarketingResourcesGroup/MarketingResourcesSMSReviewPage.vue"
                            ),
                        name: "review-sms",
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.REVIEW_SMS],
                            isSubShow: false,
                            subGroup: "sms",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.REVIEW_SMS,
                                api: assetApi.getSms,
                            })

                            if (path === true) {
                                to.params.initialSmsResponse = response
                            }
                            return path
                        },
                    },
                ],
            },
        ],
    },
]
