/**
 * 權限及狀態的名稱、資訊集中管理檔案
 */

const PERMISSIONS = {
    // ==共用模組==
    // ==系統設定==
    EDIT_SYSTEM_SETTING: "configure_system",
    // ==權限管理==
    GET_PERMISSION: "view_role_permissions",
    EDIT_PERMISSION: "edit_role_permissions",
    // ==使用者管理==
    GET_ACCOUNT: "view_user",
    ADD_ACCOUNT: "add_user",
    EDIT_ACCOUNT: "edit_user",
    FREEZE_ACCOUNT: "inactivate_user",
    UNFREEZE_ACCOUNT: "activate_user",
    SEND_ACCOUNT_MAIL: "resend_invite", // 發送用戶啟用信件
    // ==上傳==
    GET_UPLOAD: "can_upload",
    // ==下載==
    GET_DOWNLOAD: "can_download",

    // ==行銷模組==
    // ==行銷活動權限==
    GET_CAMPAIGN: "view_campaign", // 檢視 行銷活動
    ADD_CAMPAIGN: "edit_campaign", // 新增 行銷活動
    EDIT_CAMPAIGN: "edit_campaign", // 編輯 行銷活動
    DELETE_CAMPAIGN: "delete_campaign", // 刪除 行銷活動
    REVIEW_CAMPAIGN: "review_campaign", // 審核 行銷活動
    SUBMIT_CAMPAIGN: "submit_campaign", // 送出 行銷活動審核
    CANCELED_CAMPAIGN: "cancel_campaign", // 取消 行銷活動

    // ==自動化行銷模組==
    // ==自動化行銷==
    GET_JOURNEY: "view_journey", // 檢視旅程
    GET_JOURNEY_PERFORMANCE: "view_journey_performance", // 檢視旅程成效
    GET_JOURNEY_USER_LIST: "view_journey_users_list", // 檢視旅程名單 GID
    EXPORT_JOURNEY_USER_LIST: "export_journey_users_list", // 匯出旅程名單 GID
    ADD_JOURNEY: "add_journey", // 新增旅程
    EDIT_JOURNEY: "edit_journey", // 編輯旅程
    DELETE_JOURNEY: "delete_journey", // 刪除旅程
    REVIEW_JOURNEY: "review_journey", // 審核旅程
    SUBMIT_JOURNEY: "submit_journey", // 送出旅程審核
    FREEZE_JOURNEY: "inactivate_journey", // 停用旅程
    EDIT_JOURNEY_PRIORITY: "edit_journey_priority", // 編輯旅程排序
    // ==自動化行銷 sms==
    GET_SMS: "view_sms_asset", // 檢視簡訊素材
    ADD_SMS: "create_sms_asset", // 新增簡訊素材
    EDIT_SMS: "edit_sms_asset", // 編輯簡訊素材
    DELETE_SMS: "delete_sms_asset", // 刪除簡訊素材
    REVIEW_SMS: "review_sms_asset", // 審核簡訊素材
    SUBMIT_SMS: "submit_sms_asset", // 送出素材審核
    // ==自動化行銷 APP推播==
    GET_APP_PUSH: "view_push_asset", // 檢視推播素材
    ADD_APP_PUSH: "create_push_asset", // 新增推播素材
    EDIT_APP_PUSH: "edit_push_asset", // 編輯推播素材
    DELETE_APP_PUSH: "delete_push_asset", // 刪除推播素材
    REVIEW_APP_PUSH: "review_push_asset", // 審核推播素材
    SUBMIT_APP_PUSH: "submit_push_asset", // 送出素材審核

    // ==自動化行銷 EDM 相關權限==
    GET_EDM: "view_edm_asset", // 檢視 EDM 素材
    ADD_EDM: "edit_edm_asset", // 新增 EDM 素材
    EDIT_EDM: "edit_edm_asset", // 編輯 EDM 素材
    DELETE_EDM: "delete_edm_asset", // 刪除 EDM 素材
    REVIEW_EDM: "review_edm_asset", // 審核 EDM 素材
    SUBMIT_EDM: "submit_edm_asset", // 送出 EDM 素材審核

    // ==受眾模組==
    // ==受眾篩選==
    GET_AUDIENCE: "view_audience", // 檢視受眾
    ADD_AUDIENCE: "add_audience", // 新增受眾
    EDIT_AUDIENCE: "edit_audience", // 編輯受眾
    DELETE_AUDIENCE: "delete_audience", // 刪除受眾
    GET_MEMBER_LIST: "view_audience_list", // 檢視名單
    // ==標籤管理==
    EXPORT_AUDIENCE: "export_audience", // 匯出名單
    ATTACH_TAG: "assign_tag", // 執行貼標
    GET_TAG: "view_tag", // 檢視編輯編輯標籤
    EDIT_TAG: "edit_tag", // 編輯標籤
    GET_TAG_MEMBER_LIST: "view_tag_member_list", // 檢視標籤名單
    EXPORT_TAG: "export_tag_audience", // 匯出標籤名單
    DELETE_TAG: "delete_tag", // 刪除標籤
    // ==篩選貼標==
    GET_TAGGING: "view_tagging", // 新增、編輯貼標
    EDIT_TAGGING: "edit_tagging", // 編輯篩選貼標
    GET_TAGGING_MEMBER_LIST: "view_tagging_member_list", // 檢視名單
    DELETE_TAGGING: "delete_tagging", // 刪除篩選貼標
    EXPORT_TAGGING: "export_tagging", // 匯出貼標名單
    // ==貼標回中台設定==
    EDIT_TAG_HUB: "edit_tag_sync_to_datahub",
    // ==資料管理模組==
    VIEW_MEMBER_DATA: "view_member_data", // 檢視會員資料
    GET_DATA_BATCHES: "view_data_batchs", // 檢視資料批次
}

export default PERMISSIONS

// 角色
export const ROLE = {
    DEPARTMENT_STAFF: "department_staff",
    DEPARTMENT_SUPERVISOR: "department_supervisor",
    SYSTEM_MANAGER: "system_manager",
    SYSTEM_MANAGER_SUPERVISOR: "system_manager_supervisor",
    ADMINISTRATOR: "administrator",
}

// 角色中文
export const ROLE_CHINESE = {
    [ROLE.DEPARTMENT_STAFF]: "部門人員",
    [ROLE.DEPARTMENT_SUPERVISOR]: "部門主管",
    [ROLE.SYSTEM_MANAGER]: "平台管理員",
    [ROLE.SYSTEM_MANAGER_SUPERVISOR]: "平台管理員主管",
    [ROLE.ADMINISTRATOR]: "總管理員",
}

// permission scope (EffectiveRange) 自己、部門、更低階、所有、同位階或低階
export const PERMISSION_LIMIT = {
    SAME_PEOPLE: "self",
    SAME_DEPARTMENT: "department",
    LOWER_LEVEL_DEPARTMENT: "lower_level",
    SAME_OR_LOWER_LEVEL: "same_or_lower_level",
    ALL: "all",
}
