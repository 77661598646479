import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import PERMISSIONS from "@/utils/permissions/index.js"
import useRouterProtect from "@/composables/useRouterProtect/index.js"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import * as campaignsApi from "@/api/campaigns/api"
import { CAMPAIGN_EDIT_MODE } from "@/utils/status"

export default [
    {
        path: "/marketing",
        component: DefaultLayout,
        redirect: () => {
            return { name: "campaign-list" }
        },
        name: "marketing",
        meta: {
            isShow: true,
            name: "行銷模組",
            index: "4",
            position: "left",
            menuGroup: "marketing",
        },
        children: [
            // campaign 管理
            {
                path: "campaigns",
                component: () =>
                    import("@/pages/MarketingGroup/MarketingCampaignPage.vue"),
                name: "campaign-list",
                meta: createAuthRouteMeta({
                    requiresAuth: true,
                    permissions: [PERMISSIONS.GET_CAMPAIGN],
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "campaign",
                    subGroupName: "行銷活動",
                }),
            },
            {
                // eslint-disable-next-line
                path: `campaigns/:id/:mode(${CAMPAIGN_EDIT_MODE.CREATE}|${CAMPAIGN_EDIT_MODE.EDIT}|${CAMPAIGN_EDIT_MODE.REVIEW})`,
                component: () =>
                    import(
                        "@/pages/MarketingGroup/MarketingCampaignEditPage.vue"
                    ),
                name: "edit-campaign",
                meta: createAuthRouteMeta({
                    requiresAuth: true,
                    permissions: [PERMISSIONS.EDIT_CAMPAIGN],
                    isSubShow: false,
                    subGroup: "campaign",
                    subGroupName: "行銷活動",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.GET_CAMPAIGN,
                        api: campaignsApi.getCampaign,
                    })

                    if (path === true) {
                        to.params.campaignResponse = response
                    }
                    return path
                },
            },
            {
                path: "campaigns/:id",
                component: () =>
                    import(
                        "@/pages/MarketingGroup/MarketingCampaignDetailPage.vue"
                    ),
                name: "campaign-detail",
                meta: createAuthRouteMeta({
                    requiresAuth: true,
                    permissions: [PERMISSIONS.GET_CAMPAIGN],
                    isSubShow: false,
                    subGroup: "campaign",
                    subGroupName: "行銷活動",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.GET_CAMPAIGN,
                        api: campaignsApi.getCampaign,
                    })

                    if (path === true) {
                        to.params.campaignResponse = response
                    }
                    return path
                },
            },
        ],
    },
]
