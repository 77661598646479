<script setup>
    import { ElConfigProvider } from "element-plus"
    import zhTw from "element-plus/es/locale/lang/zh-tw"
    zhTw.el.datepicker.confirm = "確認"
</script>

<template>
    <el-config-provider :locale="zhTw">
        <router-view />
    </el-config-provider>
</template>

<style scoped></style>
