<script setup>
    import BaseNavbarMenuItems from "@/components/Base/BaseNavbar/BaseNavbarMenuItems.vue"
    import checkPermissions from "@/utils/checkPermissions"
    import { useUserStore } from "@/stores/user"

    defineProps({
        items: {
            type: Array,
            required: true,
        },
    })

    const userStore = useUserStore()

    /**
     * 將項目分組成單純的 menu-item 和 sub-menu-item，傳入給 element-plus menu
     * @param {Array} items - 項目列表。
     * @returns {Array} - 格式化後的項目列表。
     */
    function formatItems(items) {
        const groups = {}

        return (
            items?.reduce((result, item) => {
                if (!item.meta.hasSubMenuGroup) {
                    result.push({
                        hasSubMenuGroup: item.meta.hasSubMenuGroup,
                        subMenuGroup: item.meta.subMenuGroup,
                        item: item,
                    })
                } else {
                    if (!groups[item.meta.subMenuGroup]) {
                        groups[item.meta.subMenuGroup] = {
                            type: item.meta.subMenuGroup,
                            items: [],
                        }
                        result.push({
                            subMenuGroupIndex: item.meta.subMenuGroupIndex,
                            hasSubMenuGroup: item.meta.hasSubMenuGroup,
                            subMenuGroup: item.meta.subMenuGroup,
                            ...groups[item.meta.subMenuGroup],
                        })
                    }
                    groups[item.meta.subMenuGroup].items.push(item)
                }
                return result
            }, []) || []
        )
    }

    /**
     * 現有頂邊欄位中，可去往的第一個側邊欄
     * @param {Ref<Array>} parentMenuItem - 導航欄項目。
     * @param {Reactive} userStore - 使用者存儲的實例。
     * @returns {object} - object
     * @property {Ref<Array>} firstSidebarItems - 所有側邊第一個導航欄項目。
     */
    function getMenuItems(parentMenuItem, userStore) {
        return parentMenuItem.children
            ?.filter((item) => item.meta?.isSubShow)
            .filter((item) => {
                return checkPermissions(item.meta?.permissions, userStore)
            })
            .slice()
            .sort((a, b) => a.meta.subIndex - b.meta.subIndex)
    }
</script>
<template>
    <template v-if="items.length">
        <template v-for="(formatItem, index) of formatItems(items)">
            <el-menu-item
                v-if="
                    !formatItem.hasSubMenuGroup ||
                    !formatItem.items ||
                    formatItem.items?.length === 0
                "
                :key="'item' + index"
                data-test="sidebar-item"
                :index="formatItem.item.meta.subIndex"
                @click="() => $router.push({ name: formatItem.item.name })"
            >
                <template #title>
                    {{ formatItem.item.meta.subGroupName }}
                </template>
            </el-menu-item>
            <el-sub-menu
                v-else
                :key="'group' + index"
                :index="formatItem.subMenuGroupIndex"
                class="custom-sub-menu"
            >
                <template #title>
                    <span>{{ formatItem.subMenuGroup }}</span>
                </template>
                <el-menu-item-group>
                    <template
                        v-for="(formatSubItem, subIndex) of formatItem.items"
                        :key="subIndex"
                    >
                        <BaseNavbarMenuItems
                            v-if="getMenuItems(formatSubItem, userStore)"
                            :items="getMenuItems(formatSubItem, userStore)"
                        ></BaseNavbarMenuItems>
                    </template>
                </el-menu-item-group>
            </el-sub-menu>
        </template>
    </template>
</template>

<style scoped>
    .el-sub-menu.is-opened
        :deep(> .el-sub-menu__title > .el-sub-menu__icon-arrow) {
        @apply !rotate-0;
    }

    .el-sub-menu :deep(.el-sub-menu__icon-arrow) {
        @apply !-rotate-90;
    }

    :deep(.el-sub-menu__title .el-sub-menu__icon-arrow) {
        @apply right-[unset] left-2.5;
    }

    :deep(.custom-sub-menu.el-sub-menu.is-opened .el-sub-menu__title > span) {
        @apply opacity-50;
    }

    :deep(.custom-sub-menu.el-sub-menu .el-sub-menu__icon-arrow) {
        @apply hidden;
    }

    :deep(.el-menu-item-group__title) {
        padding: 0;
    }
</style>
