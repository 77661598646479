import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import PERMISSIONS from "@/utils/permissions/index.js"
import * as configurationApi from "@/api/configuration/api"

export default [
    {
        path: "/upload",
        name: "upload-wrapper",
        meta: {
            isShow: false,
            name: "上傳中心",
            requiresAuth: true,
            menuGroup: "upload",
            permissions: [PERMISSIONS.GET_UPLOAD],
        },
        component: DefaultLayout,
        children: [
            {
                path: "",
                component: () => import("@/pages/CommonGroup/UploadPage.vue"),
                name: "upload-list",
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: "/download",
        name: "download-wrapper",
        meta: {
            isShow: false,
            name: "下載中心",
            requiresAuth: true,
            menuGroup: "download",
            permissions: [PERMISSIONS.GET_DOWNLOAD],
        },
        component: DefaultLayout,
        children: [
            {
                path: "",
                component: () => import("@/pages/CommonGroup/DownloadPage.vue"),
                name: "download-list",
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: "/system-setting",
        name: "system-setting-wrapper",
        meta: {
            isShow: false,
            position: "",
            name: "系統設定",
            permissions: [PERMISSIONS.EDIT_SYSTEM_SETTING],
        },
        component: DefaultLayout,
        children: [
            {
                path: "",
                component: () =>
                    import("@/pages/CommonGroup/SystemSettingPage.vue"),
                name: "system-setting",
                meta: {
                    requiresAuth: true,
                },
                props: true,
                beforeEnter: async (to) => {
                    try {
                        const response =
                            await configurationApi.getConfiguration()

                        to.params.initialConfigurationResponse = response

                        return true
                    } catch (error) {
                        return false
                    }
                },
            },
        ],
    },
]
