import { instance } from "../axios"

/**
 * 取得用戶資訊
 * @param {object} [config] axios config
 * @returns {Promise} API response
 */
export function getAccountInfo(config = {}) {
    return instance.request({
        url: "/account/current/",
        method: "get",
        ...config,
    })
}

/**
 * 取得用戶權限
 * @param {object} [config] axios config
 * @returns {Promise} API response
 */
export function getAccountPermission(config = {}) {
    return instance.request({
        url: `/account/current/permissions/`,
        method: "get",
        ...config,
    })
}
