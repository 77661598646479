/**
 * format user permission
 * @param {object} permissionsResponse permissionsResponse
 * @returns {string[]} permissions array 權限列表
 */
export function formatAccountPermissions(permissionsResponse) {
    return permissionsResponse?.results
        .filter((permission) => permission.isActive)
        .map((permission) => permission.permissionId)
}
