import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import PERMISSIONS from "@/utils/permissions/index.js"
import useRouterProtect from "@/composables/useRouterProtect/index.js"
import * as usersAPI from "@/api/users/api"
import { formatUserDataToRouterProtectStyle } from "@/api/users/utils"

export default [
    {
        path: "/account-manage",
        component: DefaultLayout,
        name: "account-manage",
        redirect: "/account-manage/permissions-setting",
        meta: {
            isShow: false,
            name: "權限管理",
            index: "6",
            position: "left",
            menuGroup: "account-manage",
        },
        children: [
            {
                path: "permissions-setting", // 角色管理
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/AccountManagePermissionSettingPage.vue"
                    ),
                name: "permissions-setting",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.GET_PERMISSION],
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "permissions-setting",
                    subGroupName: "角色管理",
                },
            },
            {
                path: "accounts", // 使用者管理列表
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/AccountManageAccountsPage.vue"
                    ),
                name: "accounts-list",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.GET_ACCOUNT],
                    isSubShow: true,
                    subIndex: "2",
                    subGroup: "accounts",
                    subGroupName: "使用者管理",
                },
            },
            {
                path: "accounts/:id/edit", // 編輯使用者
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/AccountManageAccountsEditPage.vue"
                    ),
                name: "edit-accounts",
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.EDIT_ACCOUNT],
                    isSubShow: false,
                    subGroup: "accounts",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.EDIT_ACCOUNT,
                        api: usersAPI.getUser,
                        formatFunction: formatUserDataToRouterProtectStyle,
                    })

                    if (path === true) {
                        to.params.initialUserDataResponse = response
                    }
                    return path
                },
            },
        ],
    },
]
