import { instance } from "../axios"

/**
 * 取得 campaign 列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getCampaignList(config = {}) {
    return instance.request({
        url: "/campaigns/",
        method: "get",
        ...config,
    })
}

/**
 * 新增 campaign
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function createCampaign(config = {}) {
    return instance.request({
        url: `/campaigns/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得單一 campaign
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaign(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/`,
        method: "get",
        ...config,
    })
}

/**
 * 更新 campaign
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function updateCampaign(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/`,
        method: "put",
        ...config,
    })
}

/**
 * 部分更新 campaign
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function patchCampaign(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/`,
        method: "patch",
        ...config,
    })
}

/**
 * 刪除 campaign
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function deleteCampaign(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 取得單一 campaign 的分析資料
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaignAnalytics(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/analytics/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得單一 campaign A/B test的分析資料
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaignABTestResult(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/analytics/ab-test-result/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得單一 campaign 數據詳情資訊
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaignBehaviorAnalytics(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/analytics/behavior-summary/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得單一 campaign 數據的點擊成效資訊
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaignLinkAnalytics(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/analytics/campaign-link/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得單一 campaign 活動名單
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaignMemberList(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/analytics/member-list/`,
        method: "get",
        ...config,
    })
}

/**
 * 匯出單一 campaign 活動名單
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function exportCampaignMemberList(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/analytics/member-list/export/`,
        method: "post",
        ...config,
    })
}

/**
 * 提交 campaign 送審
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function submitCampaign(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/submit/`,
        method: "post",
        ...config,
    })
}

/**
 * 獲得 campaign 的審核者
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getCampaignReviewers(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/reviewer/`,
        method: "get",
        ...config,
    })
}

/**
 * 審核 campaign
 * @param {string} id campaign id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function reviewCampaign(id, config = {}) {
    return instance.request({
        url: `/campaigns/${id}/review/`,
        method: "post",
        ...config,
    })
}

/**
 * 取消活動
 * @param {string} uuid - 活動 uuid
 * @param {object} config - axios config
 * @returns {Promise} API response
 */
export function cancelCampaign(uuid, config = {}) {
    return instance.request({
        url: `/campaigns/${uuid}/cancel/`,
        method: "post",
        ...config,
    })
}
