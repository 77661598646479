import { instance } from "../axios"

/**
 * 取得受眾列表
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAudienceList(config = {}) {
    return instance.request({
        url: "/audiences/",
        method: "get",
        ...config,
    })
}

/**
 * 創建新受眾
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function createAudience(config = {}) {
    return instance.request({
        url: "/audiences/",
        method: "post",
        ...config,
    })
}

/**
 * 取得受眾資訊
 * @param {string} audienceId id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAudienceInfo(audienceId, config = {}) {
    return instance.request({
        url: `/audiences/${audienceId}/`,
        method: "get",
        ...config,
    })
}

/**
 * 修改受眾資訊
 * @param {string} audienceId id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function editAudienceInfo(audienceId, config = {}) {
    return instance.request({
        url: `/audiences/${audienceId}/`,
        method: "put",
        ...config,
    })
}

/**
 * 解鎖此受眾
 * @param {string} audienceId id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function unlockAudience(audienceId, config = {}) {
    return instance.request({
        url: `/audiences/${audienceId}/unlock/`,
        method: "post",
        ...config,
    })
}

/**
 * 上鎖此受眾
 * @param {string} audienceId id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function lockAudience(audienceId, config = {}) {
    return instance.request({
        url: `/audiences/${audienceId}/lock/`,
        method: "post",
        ...config,
    })
}

/**
 * 刪除此受眾
 * @param {string} audienceId id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function deleteAudience(audienceId, config = {}) {
    return instance.request({
        url: `/audiences/${audienceId}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 取得條件列表
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getFilterConditions(config = {}) {
    return instance.request({
        url: `/audiences/filter-conditions/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得條件列表子條件選項列表 目前僅有 dataType： List 、 String 會使用到這隻 API
 * @param {string} audienceId id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getFilterConditionsChoices(audienceId, config = {}) {
    return instance.request({
        url: `/audiences/filter-conditions/${audienceId}/choices/`,
        method: "get",
        ...config,
    })
}

/**
 * 將受眾條件送出分析獲得 Job id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function audiencesAnalyze(config = {}) {
    return instance.request({
        url: "/audiences/analyze/",
        method: "post",
        ...config,
    })
}

/**
 * 將受眾條件送出分析
 * @param {string} uuid #這邊注意帶入的 id 有可能是經由 audiencesAnalyze() 產出的 fromAudience 或是本身受眾 uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function audiencesFetch(uuid, config = {}) {
    return instance.request({
        url: `/audiences/${uuid}/fetch/`,
        method: "get",
        ...config,
    })
}

/**
 * 受眾分析出來的 GID 表格
 * @param {string} uuid #這邊注意帶入的 id 有可能是經由 audiencesAnalyze() 產出的 fromAudience 或是本身受眾 uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAudiencesMember(uuid, config = {}) {
    return instance.request({
        url: `/audiences/${uuid}/members/`,
        method: "get",
        ...config,
    })
}

/**
 * 下載名單
 * @param {string} uuid id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function exportAudiences(uuid, config = {}) {
    return instance.request({
        url: `/audiences/${uuid}/export/`,
        method: "post",
        ...config,
    })
}

/**
 * 更新受眾名稱
 * @param {string} uuid id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function editAudienceName(uuid, config = {}) {
    return instance.request({
        url: `/audiences/${uuid}/rename/`,
        method: "patch",
        ...config,
    })
}

/**
 * 取得受眾分析比較結果
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function compareAudiences(config = {}) {
    return instance.request({
        url: `/audiences/compare/`,
        method: "post",
        ...config,
        timeout: 0,
    })
}

/**
 * 確認受眾名稱是否重複
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function checkAudienceName(config = {}) {
    return instance.request({
        url: `/audiences/name-check/`,
        method: "post",
        ...config,
    })
}

/**
 * 取消受眾計算
 * @param {string} uuid id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function cancelAudienceCalculate(uuid, config = {}) {
    return instance.request({
        url: `/audiences/${uuid}/cancel-calculation/`,
        method: "post",
        ...config,
    })
}

/**
 * 受眾再分群
 * @param {string} uuid id
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function splitAudience(uuid, config = {}) {
    return instance.request({
        url: `/audiences/${uuid}/split/`,
        method: "post",
        ...config,
    })
}
