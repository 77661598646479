import { defineStore } from "pinia"
import { formatAccountPermissions } from "@/api/account/utils"
import resetAllStores from "@/utils/resetAllStores"

export const useUserStore = defineStore("user", {
    state: () => ({
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        userPermissions: JSON.parse(localStorage.getItem("userPermissions")),
        userToken: JSON.parse(localStorage.getItem("userToken")),
    }),
    getters: {
        formatUserPermissions: (state) =>
            formatAccountPermissions(state.userPermissions),
        formatUserInfo: (state) => state.userInfo,
        formatUserToken: (state) => state.userToken?.access,
        formatUserRefreshToken: (state) => state.userToken?.refresh,
    },
    actions: {
        setUserInfo(userInfo) {
            if (userInfo) {
                this.userInfo = userInfo
                localStorage.setItem("userInfo", JSON.stringify(userInfo))
                return
            }
            this.userInfo = null
            localStorage.removeItem("userInfo")
        },
        /**
         * 存取用戶 permission，附帶處理 localStorage
         * @param {object} userPermissions userPermissions
         */
        setUserPermissions(userPermissions) {
            if (userPermissions) {
                this.userPermissions = userPermissions
                localStorage.setItem(
                    "userPermissions",
                    JSON.stringify(userPermissions)
                )
                return
            }
            this.userPermissions = null
            localStorage.removeItem("userPermissions")
        },
        /**
         * 存取用戶 token，附帶處理 localStorage、axios header
         * @param {object} token token
         * @returns {void}
         */
        setUserToken(token) {
            if (token) {
                this.userToken = token
                localStorage.setItem("userToken", JSON.stringify(token))
                return
            }
            this.userToken = null
            localStorage.removeItem("userToken")
        },
        async logout() {
            await this.$router.push({ name: "login" })
            this.setUserInfo(null)
            this.setUserPermissions(null)
            this.setUserToken(null)
            resetAllStores()
        },
    },
})
